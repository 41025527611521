.hero-padding-color {
  display:flex;
  justify-content: center;
  align-items: center;
  height: 800px;

  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-width: 2px;
  border-color: rgb(229, 119, 50);
  box-shadow: 0 10px 25px rgba(0,0,0,.9);
}
.hero-background-image{
  background-size: cover ;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;

  animation-timeline: view();
  animation: hero-image linear;
  animation-range-start: contain;
  animation-range-end: contain;
}
.hero-box {
  border-radius: 15px;
  position: relative;
  max-width: 600px;
  padding: 0px;
  background: rgba(246, 231, 214, .4); 
  box-shadow: 0 12px 15px rgba(0,0,0,.5);

}
.hero-box h2{
  font-family: "ShadowsIntoLight";
  margin: 0  0 10px;
  padding: 0;
  font-size: 48px;
  text-transform: uppercase;
  text-align: center; 
  color:rgb(59, 26, 24);
}
.hero-box h4{
  font-family: "ShadowsIntoLight";
  text-align: center; 
  padding: 0;
  font-size: 32px;
  color:rgb(59, 26, 24);
}
.hero-box h3{
  font-family: "ShadowsIntoLight";
  margin: 0  0 20px;
  text-align: center; 
  padding: 0;
  font-size: 42px;
  color:rgb(59, 26, 24);
}

.hero-box-p-body{
  background: rgba(246, 231, 214, .5); 
  border-radius: 10px;
  padding: 10px;
}

.hero-box p{
  margin: 0 ;
  padding: 0;
  font-size: 18px;
  color:rgb(59, 26, 24);
}

.hero-box-closing-p{
  font-size: 42px;
  font-weight: bold;
}

@media only screen and (min-width: 600px) {
    /* Large screen, retina */
    .hero-background-image {
       background-image: url("../../public/main-background.jpg");
    }
}

/* Web and Mobile resize styles*/
@media only screen and (max-width: 600px) {
    /* Small screen, non-retina */
    .hero-background-image { 
       background-image: url("../../public/main-background-mobile.jpg");
    }

}



