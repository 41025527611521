.about-page-background-image { 
    background-image: url("../../public/about-background.jpg");
    background-size: 100% 100%;
}
.more {
  font-size: 60px;
  text-align: center; 

}
.about-padding-color {
    display:flex;
    justify-content: center;
    align-items: center;
    height: 1000px;

    border-top: 0px;
    border-width: 2px;
    border-color: rgb(229, 119, 50);
    box-shadow: 0 10px 25px rgba(0,0,0,.9);
  }
  .about-box {
    border-radius: 15px;
    position: relative;
    width: 600px;
/*May not need this just a test for now */
    height: 700px;

    max-width: 600px;
    padding: 0px;
    background: rgba(246, 231, 214, .4); 
    box-shadow: 0 12px 15px rgba(0,0,0,.5);
  
  }
  .about-box h1{
    font-family: "ShadowsIntoLight";
    margin: 0  0 10px;
    padding: 0;
    font-size: 48px;
    text-transform: uppercase;
    text-align: center; 
    color:rgb(59, 26, 24);
  }
  .about-box h2{
    font-family: "ShadowsIntoLight";
    margin: 0  0 10px;
    padding: 0;
    font-size: 48px;
    text-transform: uppercase;
    text-align: center; 
    color:rgb(59, 26, 24);
  }
  .about-box h4{
    font-family: "ShadowsIntoLight";
    text-align: center; 
    padding: 0;
    font-size: 32px;
    color:rgb(59, 26, 24);
  }
  .about-box h3{
    font-family: "ShadowsIntoLight";
    margin: 0  0 20px;
    text-align: center; 
    padding: 0;
    font-size: 42px;
    color:rgb(59, 26, 24);
  }
   .about-box p{
    line-height: 1.5;

    border-bottom: 20px;
    border-top: 20px;

    text-indent: 20px ;
    font-size: 42px;
    
    border: 15px solid rgb(59, 26, 24, 0);
  }
 
  .about-box-p-body{
    background: rgba(246, 231, 214, .5); 
    border-radius: 10px;
    padding: 10px;
  }
  
  .about-box p{
    margin: 0 ;
    padding: 0;
    font-size: 18px;
    color:rgb(59, 26, 24);
  }
  
  .about-box-closing-p{
    font-size: 42px;
    font-weight: bold;
  }
  
  @media only screen and (min-width: 600px) {
      /* Large screen, retina */
      .about-background-image {
         background-image: url("../../public/main-background.jpg");
      }
  }
  
  /* Web and Mobile resize styles*/
  @media only screen and (max-width: 600px) {
      /* Small screen, non-retina */
      .about-background-image { 
         background-image: url("../../public/main-background-mobile.jpg");
      }
  
  }
  