.map-shape {
  background: rgba(246, 231, 214, .5); 
  padding-top: 10px;
  width: 100px; 
  height: 1000px; 
}
.map-bottomnav-box{
  width: 100%;
  /* background-color: rgba(246, 231, 214, .9); */
  background-color: rgba(246, 231, 214); 
}
.map-bottomnav-column-full {
  width: 250px;
  background: rgba(246, 231, 214, .5); 
  padding-top:2px;
  padding-bottom:20px;

  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-width: 2px;
  border-color: rgb(229, 119, 50);
  box-shadow: 0 10px 15px rgba(0,0,0,.9);
}
  
#map {
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   border-radius: 10px;
}
  
  