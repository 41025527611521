.bottomnav-box{
  width: 100%;
  bottom: 0px;
 /* background-color: rgba(246, 231, 214, .9); */
  background-color: rgba(246, 231, 214); 
}


.bottomnav-row {
  width: 100%;
  height: 100%;
  background-color:black;
  
}

.bottomnav-column {
  float: left;
  width: 33%;
  background-color: transparent;
  /* Loos like notihng sets the width of 33%
  the order is missing everyting slides to the left 
  of the empty column */
  border: .1px solid rgba(0,0,0,0); 
  text-align: center;
  align-items: center;
  
  background-color: rgba(246, 231, 214); 
}

.bottomnav-column-full {
  float: center;
  width: 100%;
  background: rgba(246, 231, 214, .5); 
  padding-top:2px;
  padding-bottom:20px;

  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-width: 2px;
  border-color: rgb(229, 119, 50);
  box-shadow: 0 10px 15px rgba(0,0,0,.9);

}

.bottomnav-full-column {
  padding: 100px;
}



.bottomnav-box h2{
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  background-color: transparent;
}

@media only screen and (min-width: 500px) {
    .bottomnav-column {
      width: 33%;
      height: 100%;
    }
    .bottomnav-right {
      width: 100%;
      text-align: right;
    }
    .bottomnav-column-three {
      justify-content: center;
      align-items: center;
      float: right;
      background-color: transparent;
      /* Loos like notihng sets the width of 33%
      the order is missing everyting slides to the left 
      of the empty column */
      border: .1px solid rgba(0,0,0,0); 
      text-align: center;
      background-color: rgba(246, 231, 214); 
      height: 100%;
    }
}

fieldset {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  border: 1px solid #282c34;
  line-height: 1.6em;
}


