.join-about-box{ 
  bottom: 0px;

    display:contents;
    display:block;
    display:flow-root;
    background-image: url("../../public/sandfow.jpg");
}

.icon-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}

.join-about-column-left {  
  float: left;
  height: 100%;
  width: 48%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.join-about-column-center {
  float: left;
  width:4%;
  height: 100%;
}

.join-about-column-right {
    bottom: 0px;
    float: right;
    width: 48%;
}

.join-about-column-right-form {
  width: 80%;
  border-radius: 20px;
  border-width: 2px;
  border-color: rgb(229, 119, 50);
  box-shadow: 0 12px 15px rgba(0,0,0,.5);
  margin: 5%;
  text-align: center;
  width: 80%;
  background: rgba(246, 231, 214, .5); 
}

 .join-about-column-right-form  legend{
   color:rgb(59, 26, 24);
   font-size: 32px;
   font-weight: bold;
 }

 .join-about-column-right-form  input{
    margin-top: 10px;
    font-size: 22px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
 }

.join-about-column-right-form button{
    text-align: center;
    font-family: "ShadowsIntoLight";
    background-color: rgb(229, 119, 50);
    padding: 10px;
    border-radius: 5px;
    width: 45%;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .icon-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
    }

    .join-about-column-left{
      width: 100%;
      height: 100%;
    }

    .join-about-column-center {
      float: left;
      width:0%;
      height: 0%;
    }

    .join-about-column-right {
        bottom: 0px;
        float: right;
        width: 100%;
    }

    .join-about-column-right-form {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}

