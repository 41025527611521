.town-page-background-image { 
    background-image: url("../../public/about-background.jpg");
    background-size: 100% 100%;
    bottom: 0px;
}
.town-page-row{

  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.0);
  border: 1px solid rgba(0, 0, 0, 0.0);
  font-size: 30px;
  text-align: center;
  display:contents;
  display:block;
  display:flow-root;
}


.town-column-beach {

  float: left;
  height: 85%;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.town-column-beach-name {
  float: right;
  width: 30%;
  height: 350px;
  border-radius: 15px;
  position: relative;
  /*May not need this just a test for now */
  max-width: 900px;
  padding: 0px;
  background: rgba(246, 231, 214, .6); 
  box-shadow: 0 12px 15px rgba(0,0,0,.5);
}



.town-padding-color {
  /* now one */
  display: grid;
  column-gap: 50px;
  grid-template-columns: auto auto auto;
  padding: 10px;
  /* now one */
  display:flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-width: 2px;
  border-color: rgb(229, 119, 50, 0);
  box-shadow: 0 10px 25px rgba(0,0,0,.0);
}

.town-box {
  border-radius: 15px;
  position: relative;
  width: 100%;
  /*May not need this just a test for now */
  height: 300px;
  max-width: 900px;
  padding: 0px;
  background: rgba(246, 231, 214, .4); 
  box-shadow: 0 12px 15px rgba(0,0,0,.5);
  
}

.town-box img{
    max-width: 180px;
    float: left;
    padding: 10px;
    border-radius: 10px;
  }

  .town-box h1{
    font-family: "ShadowsIntoLight";
    padding: 0;
    font-size: 48px;
    text-transform: uppercase;
    color:rgb(59, 26, 24);
  }
  .town-box h2{
    font-family: "ShadowsIntoLight";
    margin: 0  0 10px;
    padding: 0;
    font-size: 48px;
    text-transform: uppercase;
    color:rgb(59, 26, 24);
  }
  .town-box h4{
    font-family: "ShadowsIntoLight";
    text-align: center; 
    padding: 0;
    font-size: 32px;
    color:rgb(59, 26, 24);
  }
  .town-box h3{
    font-family: "ShadowsIntoLight";
    margin: 0  0 20px;
    text-align: center; 
    padding: 0;
    font-size: 42px;
    color:rgb(59, 26, 24);
  }
   .town-box p{
    line-height: 1.5;

    border-bottom: 20px;
    border-top: 20px;

    text-indent: 20px ;
    font-size: 42px;
    
    border: 15px solid rgb(59, 26, 24, 0);
  }
 
  .town-box-p-body{
    background: rgba(246, 231, 214, .5); 
    border-radius: 10px;
    padding: 10px;
  }
  
  .town-box p{
    margin: 0 ;
    padding: 0;
    font-size: 18px;
    color:rgb(59, 26, 24);
  }
  
  .town-box-closing-p{
    font-size: 42px;
    font-weight: bold;
  }
  
  @media only screen and (max-width: 600px) {
      /* Large screen, retina */
      .town-background-image {
         background-image: url("../../public/main-background.jpg");
      }
  .town-box {
    border-radius: 15px;
    position: relative;
    width: 100%;
/*May not need this just a test for now */
    height: 700px;
    max-width: 900px;
    padding: 0px;
    background: rgba(246, 231, 214, .4); 
    box-shadow: 0 12px 15px rgba(0,0,0,.5);
  
  }

  }
  
  /* Web and Mobile resize styles*/
  @media only screen and (max-width: 600px) {
      /* Small screen, non-retina */
      .town-background-image { 
         background-image: url("../../public/main-background-mobile.jpg");
      }
  
  }
  