.cape-font-large {
  font-family: "ShadowsIntoLight";
  font-size: 40px;
  font-weight: bold;
}

.cape-font-small {
  font-family: "ShadowsIntoLight";
  font-size: 25px;
  font-weight: bold;
}

.cape-font-med {
  font-family: "ShadowsIntoLight";
  font-size: 32px;
  font-weight: bold;
}

.topnav-text-color {
  color:rgb(59, 26, 24);
}

.topnav-hover {
 position: relative;
}

.topnav-padding-color {
 position: static;
  padding: 0px;
  background: rgba(246, 231, 214, .5); 
  box-shadow: 0 10px 15px rgba(0,0,0,.9);

  }


