@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "ShadowsIntoLight";
  src: local("ShadowsIntoLight"),
  url("./fonts/ShadowsIntoLight-Regular.ttf") format("truetype");

}


