.carousel-column {
  float: left;
  width: 30%;
  background-color: transparent;
  /* Loos like notihng sets the width of 33%
  the order is missing everyting slides to the left 
  of the empty column */
  text-align: center;
  align-items: center;

  background-color: rgba(246, 231, 214, .9); 
}

@media only screen and (max-width: 600px) {
    .carousel-column {
      width: 33%;
      height: 100%;

  background-color: rgba(246, 231, 214, .9); 
    }
}

/* Carousel of beachs in bottom nav or anywher*/
.carousel {
  /* the vw and vh is reall 100% but virtural for the div we are in not the page */
  /* This all about with height of the beach may wasnt increas or decreas to make it look good */
  justify-content: center;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: rgba(246, 231, 214, .9); 
}

/* Not sure if I need this anymore */
.carousel > ul {
  margin: 0;
  padding: 0;
}

.carousel-img{
  align-items: center;
  /* Border arround seal */
  border-color:aqua;
  border-width: 0px;

}

.carousel-buttons {
  text-align: center;
  flex-direction: column;
  /*  need to delete this one */
  /*border-width: 10px; */
  border-color: #282c34;
  border-radius: 0px 0px 30px 30px;

  background-color: rgba(246, 231, 214, .9); 
}

.carousel-button {
  width: 40px;
  background-image: url("../../public/icon/buttons/icons8-round-48.png");
}

.carousel-button-left {
  display: block;
  width: 85px;
  height: 85px;
  background-image: url("../../public/icon/buttons/icons8-left-arrow-100.png");
}
.carousel-button-right {
  display: block;
  width: 100px;
  height: 85px;
  background-image:url("../../public/icon/buttons/icons8-right-arrow-100.png") ;
}

.carousel-button:hover{
  /* Change baground color */

  background-color: rgba(246, 231, 214, .9); 
}

.carousel-button:focus{
  color: white;
  background-color: rgba(0, 0, 0, .2);
}
.carousel-button:focus {
  outline: 1px solid black;
}

.carousel-button.prev{
left: 1rem;
}

.carousel-button.next{
    right: 1rem;
}

.button-arrow {
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.indicators {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.indicator-buttons{
  border: none;
  cursor: pointer;
}

.indicators > button{
  margin:5px;
  background: none;
}

.indicator-symbol{
  color:#26343f;
}

.indicator-symbol-active{
  color:#ffffff;
}

.carousel-item{
  display: inline-flex;
  vertical-align:middle;
  align-items: center;
  /*  comment need this to be transparent baground color or shaddow light white or gray*/

  background-color: rgba(246, 231, 214, .9); 
}

.inner {
  white-space: nowrap;
  vertical-align:auto;
  align-items: center;
  text-align: center;
  flex-direction: column;

  border-color: #282c34;
  border-width: 0px;

}
.carousel-item-text {
  text-align: center;
  align-items: center;
  /* need better colrser possible bod font */
  font-size: 25px;
  font-weight: bold;
  color:rgb(59, 26, 24);
}
.carousel-img{
  width: 300px;
  height: 300px;
  padding: 10px;
  align-items: center;
}
/* Not sure if I need this anymore */
.carousel > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
/*Not using this one. If not delete */
.slide {
  position: absolute;
  inset: 0;
  opacity: 0;
}
.slide > img{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
} 
.slide[data-active] {
  opacity: 1;
}
video {
  display: block;
  margin: auto;
}